import React, { useState, useEffect, useRef, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group';
import ClickNHold from 'react-click-n-hold'; 
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Alert from "./alert"
import { getItem, setItem, removeItem} from "../services/session"

const TransitionFamilies = props => {
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [familiaSelected,setFamiliaSelected] =useState("");
  const [familiaStringSelected, setFamiliaStringSelected] = useState("");
 
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const onExit = () =>{
    console.log("-->"+familiaSelected)
    props.callbackValues(familiaSelected)
  } 


  
  const handleSelectFamiliaChange= (e) =>{
    console.log("familia selected="+e.target.value)
    setFamiliaSelected(e.target.value)

  }
  const handleClickUp = (e) => {
    e.preventDefault();
    setShowAlertStep(false)
    props.handleStepActiveUp(props.stepActive)
  }

  const handleClickDown = (e) => {
    e.preventDefault();
    props.handleStepActiveDown(props.stepActive)
  }


  useEffect( () => {
  }, []);


  return (
      <span>
      <CSSTransition
        in={props.stepActive === props.index}
        timeout={300}
        onExit={onExit}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit
      >
       <div className="steps-panel">
          <div className="steps-panel-scroller">
            <div className="field-wrapper field-wrapper-select">
            <select name="origenmares"  id="origenmares"  className={props.callbackValues.familiaSelected && 'dirty'}
              onChange={handleSelectFamiliaChange} >
              <option key="1" value=""></option>
                { props.arrayData.map((data) => (
                <option key={data.id_familia} value={data.id_familia}>{data.descripcio}</option>
                )) }
              </select>
              <label htmlFor="origenmares">Families</label>
            </div>
          </div>
          <div className="steps-nav">
            <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
              <span>Prev</span>
            </button>
            <button onClick={handleClickUp} className='steps-nav-btn steps-nav-btn-next'>
              <span>Next</span>
            </button>
          </div>
        </div>
    </CSSTransition>
    <CSSTransition
        in={showAlertStep}
        timeout={300}
        classNames={'alertright'}
        unmountOnExit>
      <Alert type="error">
        <div className="alert-message">
        {messageAlertStep}
        </div>
        <span className="alert-close" onClick={closeAlertStep}>
          <i className="icon-close"></i>
        </span>
      </Alert>
  </CSSTransition>
 </span>
  )
}

export default TransitionFamilies
