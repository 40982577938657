import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import Alert from "./alert"
import { getItem, setItem} from "../services/session"


const TransitionFamilies = props => {
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");

  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  console.log("props"+JSON.stringify(props))

  const handleCheck= (e) =>{
    let visites=getItem("userGranjaTecVisites");
    let fam, subfam,checked;
    fam = e.target.name.split("_")[0];
    subfam = e.target.name.split("_")[1];
    checked=e.target.checked;
    for (var i =0; i<visites.length;i++){
      if (visites[i].id_familia==fam){
        for (var j =0; j<visites[i].subfamilies.length;j++){
            if (visites[i].subfamilies[j].id_subfamilia==subfam){
              visites[i].subfamilies[j]["checked"]=checked
              setItem("userGranjaTecVisites",visites)
              return
            }
        }
      }
    }
  }

  const handleCheckDefault = (fam, subfam) => {
    let visites=getItem("userGranjaTecVisites");
    console.log("visites?"+visites)
    for (var i =0; i<visites.length;i++){
      if (visites[i].id_familia==fam){
        for (var j =0; j<visites[i].subfamilies.length;j++){
            if (visites[i].subfamilies[j].id_subfamilia==subfam){
              let c=(visites[i].subfamilies[j]["checked"]?true:false);
              //console.log("------>>>>>"+c)
              return c;
            }
        }
      }
    }
  }

  const handleClickDown = (e) => {
    e.preventDefault();
    setShowAlertStep(false)
    props.handleStepActiveDown(props.stepActive)
  }

  const handleClickUp = (e) => {
    e.preventDefault();
    let visites=getItem("userGranjaTecVisites");
    //validar que hi ha selecionada alguna opció
    for (var i =0; i<visites.length;i++){
       for (var j =0; j<visites[i].subfamilies.length;j++){
            if (visites[i].subfamilies[j].checked) {
              props.handleStepActiveUp(props.stepActive)
              return
            }
        }
    }
    setMessageAlertStep("Has de seleccionar al menys una sub família")
    setShowAlertStep(true)
  }

  useEffect( () => {
    console.log(props.familia)
    console.log(JSON.stringify(props.familia))
   
    }, []);

  return (
      <span>
      <CSSTransition
        in={props.stepActive == props.index}
        timeout={300}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit>

       <div className="steps-panel">
          <div className="steps-panel-scroller">
              { props.arrayData.map((familia, item) => (
                <div>
                  <div className="multicheck-label" key={item}>
                    <span className="multicheck-label-main">Família</span> : <span className="multicheck-label-value">{familia.descripcio}</span>
                  </div>
                  <div className="field-wrapper-row">
                    <div className="field-wrapper-col-12">
                      { familia.subfamilies.map((subfamilia, item) => (
                        <div className="field-wrapper field-wrapper-checkbox">
                          <input
                              className="field-input" 
                              key={Math.random()}
                              type="checkbox" 
                              name={`${familia.id_familia}_${subfamilia.id_subfamilia}`}
                              defaultChecked={handleCheckDefault(familia.id_familia,subfamilia.id_subfamilia)}
                              onChange={handleCheck}
                              autoComplete='off' 
                              />
                            <label htmlFor={`${familia.id_familia}_${subfamilia.id_subfamilia}`}>{subfamilia.dessubfamilia}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
              }
          </div>
          <div className="steps-nav">
            <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
              <span>Prev</span>
            </button>
            <button onClick={handleClickUp} className='steps-nav-btn steps-nav-btn-next'>
              <span>Next</span>
            </button>
          </div>
        </div>
    </CSSTransition>
    <CSSTransition
        in={showAlertStep}
        timeout={300}
        classNames={'alertright'}
        unmountOnExit>
      <Alert type="error">
        <div className="alert-message">
        {messageAlertStep}
        </div>
        <span className="alert-close" onClick={closeAlertStep}>
          <i className="icon-close"></i>
        </span>
      </Alert>
  </CSSTransition>
 </span>
  )
}

export default TransitionFamilies
